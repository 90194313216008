<template>
  <main>
    <Tabelle v-if="Verband() === 0" tableFunction="getZAM" titel="in eVEWA zu aktualisieren" :reloadTime="60" :showAustritt="true" />
    <span ref="Antraege" />
    <Tabelle tableFunction="getAnträge" titel="offene Mitgliedsanträge" :reloadTime="60" :showAustritt="false" />
    <span ref="Interessenten" />
    <Tabelle tableFunction="getInteressenten" titel="Interessenten" :reloadTime="60" :showAustritt="false" />
    <Tabelle v-if="Verband() === 0" tableFunction="getFehlendMitgliedsnummer" titel="fehlende Mitgliedsnummern" :reloadTime="60" :showAustritt="false" />
    <Tabelle tableFunction="getFehlendDaten" titel="fehlende Mitgliedsdaten" :reloadTime="60" :showAustritt="false" />
    <Tabelle tableFunction="getWechsel1" titel="angefragte Verbandswechsel (von uns weg)" :reloadTime="60" :showAustritt="false" />
    <Tabelle v-if="Verband() === 0" titel="bestätigte Verbandswechsel (von uns weg)" tableFunction="getWechsel2" :reloadTime="60" :showAustritt="false" />
    <Tabelle tableFunction="getWechsel4" titel="Verbandswechsel zu KV" :reloadTime="60" :showAustritt="false" />
    <Tabelle v-if="Verband() === 0" tableFunction="getWechsel3" titel="Verbandswechsel zu KV (Zustimmung anderer LV ausstehend)" :reloadTime="60" :showAustritt="false" />
    <Tabelle tableFunction="getAustritteUnbest" titel="offene Austritte" :reloadTime="60" :showAustritt="true" />
    <Tabelle tableFunction="get34" titel="Alter: 34" :reloadTime="60" :showAustritt="false" />
    <Tabelle tableFunction="get35" titel="Alter: 35+" :reloadTime="60" :showAustritt="false" />
    <Tabelle tableFunction="getAustritte" titel="letzte Austritte/Ausschlüsse" :reloadTime="60" :showAustritt="true" />
  </main>
</template>

<script>
import Tabelle from '../components/Tabelle'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'ToDo',
  mixins: [globalFunctions],

  components: {
    Tabelle
  },

  data () {
    return {

    }
  },

  methods: {

  },

  computed: {

  },

  mounted: function () {
    this.$nextTick(function () {
      if (this.$route.hash) {
        const refName = this.$route.hash.replace('#', '')
        setTimeout(() => {
          var element = this.$refs[refName]
          if (element) element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }, 150)
        setTimeout(() => {
          var element = this.$refs[refName]
          if (element) element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }, 500)
      }
    })
  }
}

</script>

<style scoped>
h2:nth-child(1) {
  margin: 0;
}
</style>
