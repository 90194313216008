<template>
  <nav>
    <router-link v-if="Zugriffsrechte() !== 1" to="/" :class="Zugriffsrechte() === 0 ? 'breit' : 'Drittel'">Übersicht</router-link>
    <router-link to="/mitglieder" :class="Zugriffsrechte() === 0 ? 'breit' : (Zugriffsrechte() === 1 ? 'breiter' : 'Drittel')">Mitglieder</router-link>
    <router-link v-if="Zugriffsrechte() !== 1" to="/todo" :class="Zugriffsrechte() === 0 ? 'breit' : 'Drittel'">ToDo's</router-link>
    <router-link v-if="Zugriffsrechte() === 0" to="/mitglied/neu" class="schmaler">+</router-link>
  </nav>
  <router-view />
</template>

<script>
// import Push from 'push.js'
import axios from 'axios'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Home',
  mixins: [globalFunctions],

  components: {

  },

  data () {
    return {

    }
  },

  methods: {
    loadPrivileges: function () {
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
        function: 'login',
        username: localStorage.username,
        password: localStorage.password
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          this.logout()
        } else if (response.data === 'Wartung') {
          alert('Wartungspause')
        } else {
          localStorage.Verband = response.data.Verband
          localStorage.Zugriffsrechte = response.data.Zugriffsrechte
        }
      })
    }
  },

  computed: {

  },

  mounted: function () {
    /* setInterval(() => {
      Push.close('Mitgliedsdaten')
      Push.create('Neue Mitgliedsdaten', {
        body: 'Es wurden neue Mitgliedsdaten im Mitgliederverwaltungssystem eingetragen.',
        icon: '/img/icons/android-chrome-512x512.png',
        onClick: () => {
          window.focus()
          this.$router.push('/todo')
        },
        tag: 'Mitgliedsdaten'
      })
    }, 60 * 1000) */

    this.loadPrivileges()
  }
}

</script>

<style scoped>
nav {
  height: var(--heightMenu);
}

nav a {
  height: calc(var(--heightMenu) - 1rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline-block;
  overflow: hidden;
  background: var(--gelb);
  color: var(--weiß);
  font-family: 'Anybody ExtraBold';
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: center;
}

nav a.breiter {
  width: 100vw;
}

nav a.breit {
  width: calc((100vw - 10vw - 3*5px)/3);
  margin-right: 5px;
}

nav a.Drittel {
  width: calc((100vw - 2*5px)/3);
  margin-right: 5px;
}

nav a.Drittel:last-of-type {
  margin-right: 0;
}

nav a.schmal {
  width: calc(20vw);
  margin-right: 0;
}

nav a.schmaler {
  width: 10vw;
  margin-right: 0;
}

nav a.router-link-exact-active {
  background: var(--magenta);
}

main {
  height: calc(100vh - var(--heightMenu) - 2rem);
  overflow-x: auto;
  padding: 1rem;
}
</style>
