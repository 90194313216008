import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Einstellungen from '../views/Einstellungen.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Mitglieder from '../views/Mitglieder.vue'
import Fördermitglieder from '../views/Fördermitglieder.vue'
import ToDo from '../views/ToDo.vue'
import Mitglied from '../views/Mitglied.vue'
import Umlage from '../views/Umlage.vue'
import Zugriffsrechte from '../scripts/globalFunctions'

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      mailAccount: true
    }
  },
  {
    path: '/einstellungen',
    name: 'Einstellungen',
    component: Einstellungen,
    meta: {
      requiresAuth: true,
      mailAccount: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
          mailAccount: false
        }
      },
      {
        path: '/mitglieder',
        name: 'Mitglieder',
        component: Mitglieder,
        meta: {
          requiresAuth: true,
          mailAccount: true
        },
        children: [
          {
            path: '/mitglieder/:KV',
            name: 'Kreisverband',
            component: Mitglieder,
            meta: {
              requiresAuth: true,
              mailAccount: false
            }
          }
        ]
      },
      {
        path: '/foerdermitglieder',
        name: 'Fördermitglieder',
        component: Fördermitglieder,
        meta: {
          requiresAuth: true,
          mailAccount: false
        }
      },
      {
        path: '/todo',
        name: 'ToDo',
        component: ToDo,
        meta: {
          requiresAuth: true,
          mailAccount: false
        }
      },
      {
        path: '/mitglied/:mitgliederId',
        name: 'Mitglied',
        component: Mitglied,
        meta: {
          requiresAuth: true,
          mailAccount: false
        }
      },
      {
        path: '/umlage',
        name: 'Umlage',
        component: Umlage,
        meta: {
          requiresAuth: true,
          mailAccount: false
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const mailAccount = to.matched.some(record => record.meta.mailAccount)
  const login = localStorage.username && localStorage.password

  if (requiresAuth && !login) next('login')
  else if (to.matched.some(record => record.meta.stay)) next()
  else if (!requiresAuth && login) next('Dashboard')
  else if (!mailAccount && Zugriffsrechte.methods.Zugriffsrechte() === 1) next('Mitglieder')
  else next()
})

export default router
