<template>
  <main>
    <div v-on:click="go('MitgliederDD')" v-if="Verband() === 0" class="parallelogramm button drittel blau">
      <p class="titel">Mitglieder Dresden</p>
      <p class="inhalt">{{stats.Kreisverband[1]}}</p>
    </div>
    <div v-on:click="go('MitgliederL')" v-if="Verband() === 0" class="parallelogramm button drittel blau">
      <p class="titel">Mitglieder Leipzig</p>
      <p class="inhalt">{{stats.Kreisverband[2]}}</p>
    </div>
    <div v-on:click="go('MitgliederCH')" v-if="Verband() === 0" class="parallelogramm button drittel blau">
      <p class="titel">Mitglieder Chemnitz</p>
      <p class="inhalt">{{stats.Kreisverband[3]}}</p>
    </div>

    <div v-on:click="go('Mitglieder')" class="parallelogramm button normal magenta">
      <p class="titel">Mitgliederzahl</p>
      <p class="inhalt">{{stats.Mitglieder}}</p>
    </div>
    <div v-on:click="go('Anträge')" class="parallelogramm button normal magenta">
      <p class="titel">offene Mitgliedsanträge</p>
      <p class="inhalt">{{stats.Anträge}}</p>
    </div>
    <div class="parallelogramm normal magenta">
      <p class="titel">Durchschnittsalter: <b>{{parseFloat(stats.Alter).toFixed(1).replace('.', ',')}} Jahre</b></p>
      <p class="titel"><br /></p>
      <p class="titel">Frauenanteil: <b>{{parseFloat(100 * stats.Frauen / stats.Mitglieder).toFixed(1).replace('.', ',')}}%</b></p>
    </div>
    <div v-on:click="go('Interessenten')" class="parallelogramm button normal magenta">
      <p class="titel">Interessenten</p>
      <p class="inhalt">{{stats.Interessenten}}</p>
    </div>
    <div class="parallelogramm hoch magenta">
      <p class="titel">{{Beitragskategorien(0)}}: <b>{{stats.Beitragskategorie[0]}}</b><br>
                       {{Beitragskategorien(1)}}: <b>{{stats.Beitragskategorie[1]}}</b><br>
                       {{Beitragskategorien(2)}}: <b>{{stats.Beitragskategorie[2]}}</b><br>
                       {{Beitragskategorien(3)}}: <b>{{stats.Beitragskategorie[3]}}</b></p>
    </div>
    <div v-on:click="go('Umlage')" class="parallelogramm button hoch magenta">
      <p class="titel">monatliche Mitgliedsbeiträge</p>

      <span v-if="Verband() > 0">
        <p class="inhalt">{{parseFloat(stats.Beitrag).toFixed(2).replace('.', ',')}} €</p>
        <p class="titel">(davon&nbsp;Land:&nbsp;<b>{{(stats.Beitragskategorie[0] * 0.85 + stats.Beitragskategorie[1] * 1.35 + stats.Beitragskategorie[2] * 1.85 + stats.Beitragskategorie[3] * 3.35).toFixed(2).replace('.', ',')}} €</b> | davon&nbsp;Bund:&nbsp;<b>{{(stats.Mitglieder * 0.75).toFixed(2).replace('.', ',')}} €</b>)</p>
      </span>

      <span v-if="Verband() === 0">
        <p class="titel">Kreisverbände: <b>{{parseFloat(stats.Beitrag - (stats.Beitragskategorie[0] * 0.85 + stats.Beitragskategorie[1] * 1.35 + stats.Beitragskategorie[2] * 1.85 + stats.Beitragskategorie[3] * 3.35) - stats.Mitglieder * 0.75).toFixed(2).replace('.', ',')}} €</b></p>
        <p class="titel">Landesverband: <b>{{(stats.Beitragskategorie[0] * 0.85 + stats.Beitragskategorie[1] * 1.35 + stats.Beitragskategorie[2] * 1.85 + stats.Beitragskategorie[3] * 3.35).toFixed(2).replace('.', ',')}} €</b></p>
        <p class="titel">Bundesverband: <b>{{(stats.Mitglieder * 0.75).toFixed(2).replace('.', ',')}} €</b></p>
      </span>
    </div>

    <div v-on:click="go('Einstellungen')" class="parallelogramm button blau">
      <p class="titel">Einstellungen</p>
    </div>
    <div v-on:click="logout" class="parallelogramm button blau">
      <p class="titel">Logout</p>
    </div>

    <h1>Mitgliederentwicklung</h1>
    <div class="parallelogramm gelb button Viertel" :class="{magenta: rangeStats === 31}" v-on:click="updateChart(31)"><p>1 Monat</p></div>
    <div class="parallelogramm gelb button Viertel" :class="{magenta: rangeStats === 365}" v-on:click="updateChart(365)"><p>1 Jahr</p></div>
    <div class="parallelogramm gelb button Viertel" :class="{magenta: rangeStats === 5 * 365}" v-on:click="updateChart(5 * 365)"><p>5 Jahre</p></div>
    <div class="parallelogramm gelb button Viertel" :class="{magenta: rangeStats === null}" v-on:click="updateChart(null)"><p>Maximum</p></div>
    <div class="chart"><canvas id="mitgliederentwicklung" /></div>
  </main>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Dashboard',
  mixins: [globalFunctions],

  components: {

  },

  data () {
    return {
      stats: { Kreisverband: [], Beitragskategorie: [] },
      charts: { mitgliederentwicklung: null },
      rangeStats: 365
    }
  },

  methods: {
    getData: function () {
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
        function: 'getStats',
        username: localStorage.username,
        password: localStorage.password
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.stats = response.data

        this.stats.Kreisverband = []
        for (var KV = 1; KV <= 3; KV++) {
          this.stats.Kreisverband[KV] = 0
          this.stats.Kreisverbände.forEach((sample) => {
            if (sample.Kreisverband === KV) this.stats.Kreisverband[KV] = sample.Anzahl
          })
        }
        this.stats.Kreisverbände = null

        this.stats.Beitragskategorie = []
        for (var Kat = 0; Kat < 4; Kat++) {
          this.stats.Beitragskategorie[Kat] = 0
          this.stats.Kategorien.forEach((sample) => {
            if (sample.Beitragskategorie === Kat) this.stats.Beitragskategorie[Kat] = sample.Anzahl
          })
        }
        this.stats.Kategorien = null
      })
    },

    loadChart: function () {
      var data = []

      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
        function: 'getEntwicklung',
        username: localStorage.username,
        password: localStorage.password
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        data = response.data
        this.displayChart(data)
      })
    },

    displayChart: function (data) {
      var datasets = []
      var yAxes = []
      if (this.Verband() === 0) {
        datasets.push({
          type: 'line',
          yAxisID: 'KVs',
          label: 'Dresden',
          data: data.Kreisverband.map(row => {
            return {
              x: new Date(row.Datum),
              y: row.DD
            }
          }),
          borderColor: '#FF1F99'
        },
        {
          type: 'line',
          yAxisID: 'KVs',
          label: 'Leipzig',
          data: data.Kreisverband.map(row => {
            return {
              x: new Date(row.Datum),
              y: row.L
            }
          }),
          borderColor: '#FFF240'
        },
        {
          type: 'line',
          yAxisID: 'KVs',
          label: 'Chemnitz',
          data: data.Kreisverband.map(row => {
            return {
              x: new Date(row.Datum),
              y: row.CH
            }
          }),
          borderColor: '#1DBAFF'
        })
        yAxes.push({
          id: 'KVs',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Kreisverbände'
          },
          stacked: false,
          ticks: {
            beginAtZero: false
          },
          type: 'linear',
          gridLines: {
            lineWidth: 0
          }
        })
      }
      datasets.push({
        type: 'bar',
        yAxisID: 'Beitrag',
        label: this.Beitragskategorien(0),
        data: data.Beitragskategorie.map(row => {
          return {
            x: new Date(row.Datum),
            y: row.K0
          }
        }),
        backgroundColor: '#FFED00'
      },
      {
        type: 'bar',
        yAxisID: 'Beitrag',
        label: this.Beitragskategorien(1),
        data: data.Beitragskategorie.map(row => {
          return {
            x: new Date(row.Datum),
            y: row.K1
          }
        }),
        backgroundColor: '#E5007D'
      },
      {
        type: 'bar',
        yAxisID: 'Beitrag',
        label: this.Beitragskategorien(2),
        data: data.Beitragskategorie.map(row => {
          return {
            x: new Date(row.Datum),
            y: row.K2
          }
        }),
        backgroundColor: '#FFED00'
      },
      {
        type: 'bar',
        yAxisID: 'Beitrag',
        label: this.Beitragskategorien(3),
        data: data.Beitragskategorie.map(row => {
          return {
            x: new Date(row.Datum),
            y: row.K3
          }
        }),
        backgroundColor: '#009EE3'
      })
      yAxes.push({
        id: 'Beitrag',
        position: 'left',
        scaleLabel: {
          display: true,
          labelString: 'Beitragskategorien'
        },
        stacked: true,
        ticks: {
          beginAtZero: true
        },
        type: 'linear',
        gridLines: {
          lineWidth: 1
        }
      })

      this.charts.mitgliederentwicklung = new Chart(document.getElementById('mitgliederentwicklung'), {
        data: {
          datasets: datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (item, data) => {
                if (this.Verband() === 0) {
                  if (item.datasetIndex < 3) {
                    return ['Dresden', 'Leipzig', 'Chemnitz'][item.datasetIndex] + ': ' + data.datasets[item.datasetIndex].data[item.index].y
                  } else {
                    return this.Beitragskategorien(item.datasetIndex - 3) + ': ' + data.datasets[item.datasetIndex].data[item.index].y
                  }
                } else {
                  return this.Beitragskategorien(item.datasetIndex) + ': ' + data.datasets[item.datasetIndex].data[item.index].y
                }
              },
              footer: (tooltipItems, data) => {
                var start = 0
                if (this.Verband() === 0) start = 3
                var sum = 0
                for (var i = start; i < start + 4; i++) {
                  sum += parseInt(data.datasets[i].data[tooltipItems[0].index].y)
                }
                return 'Gesamt: ' + sum
              }
            }
          },
          legend: {
            position: 'bottom'
          },
          elements: {
            line: {
              tension: 0,
              backgroundColor: 'transparent'
            },
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [{
              type: 'time',
              offset: true,
              ticks: {
                min: this.rangeStats ? (Date.now() / (1000 * 60 * 60 * 24) - this.rangeStats) * (1000 * 60 * 60 * 24) : null
              },
              time: {
                unit: 'month',
                round: 'day',
                displayFormats: {
                  day: 'DD.MM.YY'
                },
                tooltipFormat: 'DD.MM.YYYY'
              },
              stacked: true,
              gridLines: {
                display: false
              }
            }],
            yAxes: yAxes
          }
        }
      })
    },

    updateChart: function (range) {
      this.rangeStats = range
      this.charts.mitgliederentwicklung.options.scales.xAxes[0].ticks.min = this.rangeStats ? (Date.now() / (1000 * 60 * 60 * 24) - this.rangeStats) * (1000 * 60 * 60 * 24) : null
      this.charts.mitgliederentwicklung.options.scales.xAxes[0].time.unit = (this.rangeStats === null || this.rangeStats > 365) ? 'quarter' : (this.rangeStats > 31 ? 'month' : 'day')
      this.charts.mitgliederentwicklung.update()
    }
  },

  computed: {

  },

  mounted: function () {
    this.getData()
    setInterval(this.getData, 2 * 60 * 1000)
    this.loadChart()
    setInterval(this.loadChart, 60 * 60 * 1000)
  }
}

</script>

<style scoped>
h1 {
  float: left;
  margin-top: 1rem;
  width: 100%;
}

.parallelogramm {
  /* transform: skewX(-15deg); */
  width: calc(100% / 2 - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  font-size: 2rem;
  padding: max(1.5rem, min(2.5%, 20px));
  margin: 1rem;
  float: left;
  text-align: center;
  border-radius: 1em;
}

@media screen and (orientation:portrait) {
  .parallelogramm {
    width: calc(100% - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  }
}
@media screen and (orientation:landscape) {
  .parallelogramm {
    width: calc(100% / 2 - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  }
}

.parallelogramm.drittel {
  font-size: 1.5em;
  width: calc(100% / 3 - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
}

.button {
  cursor: pointer;
}

.parallelogramm.normal {
  min-height: calc(1.251 * 3em);
}

.parallelogramm.hoch {
  min-height: calc(1.25 * 4em);
}

.parallelogramm.Viertel {
  width: calc(100% / 4 - 2rem - 2 * max(1rem, min(2%, 10px)));
  padding: max(1rem, min(2%, 10px));
}

.parallelogramm.blau {
  color: var(--weiß);
  background: var(--blau);
}

.parallelogramm.gelb {
  color: var(--magenta);
  background: var(--gelb);
}

.parallelogramm.magenta {
  color: var(--weiß);
  background: var(--magenta);
}

.parallelogramm p {
  /* transform: skewX(15deg); */
  margin: 0;
  line-height: 1.25em;
}

.parallelogramm .inhalt {
  font-size: 2em;
  font-family: 'Anybody ExtraBold';
}

b {
  font-family: 'Anybody ExtraBold';
  white-space: nowrap;
}

.chart {
  float: left;
  width: calc(100% - 2rem);
  height: 40vh;
  background: var(--weiß);
  border-radius: 2em;
  margin: 1rem;
}
</style>
