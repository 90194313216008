<template>
  <main>
    <div v-if="Zugriffsrechte() === 1" v-on:click="go('Passwort')" class="parallelogramm button blau">
      <p class="titel">Passwort ändern</p>
    </div>
    <div v-if="Zugriffsrechte() === 1" v-on:click="logout" class="parallelogramm button blau">
      <p class="titel">Logout</p>
    </div>

    <Tabelle tableFunction="getMitglieder" :KV="$route.params.KV" :showCopy="true" />
    <Tabelle titel="Fördermitglieder" tableFunction="getFördermitglieder" :KV="$route.params.KV" :showCopy="true" />
  </main>
</template>

<script>
import Tabelle from '../components/Tabelle'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Mitglieder',
  mixins: [globalFunctions],

  components: {
    Tabelle
  },

  data () {
    return {

    }
  },

  methods: {

  },

  computed: {

  },

  mounted: function () {

  }
}

</script>

<style scoped>
.parallelogramm {
  transform: skewX(-15deg);
  width: calc(100% / 2 - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  font-size: 2rem;
  padding: max(1.5rem, min(2.5%, 20px));
  margin: 1rem;
  float: left;
  text-align: center;
}

@media screen and (orientation:portrait) {
  .parallelogramm {
    width: calc(100% - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  }
}
@media screen and (orientation:landscape) {
  .parallelogramm {
    width: calc(100% / 2 - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  }
}

.parallelogramm.button {
  cursor: pointer;
}

.parallelogramm.blau {
  color: var(--weiß);
  background: var(--blau);
}

.parallelogramm p {
  transform: skewX(15deg);
  margin: 0;
  line-height: 1.25em;
}
</style>
