<template>
  <main>
    <h1>Mitglied bearbeiten</h1>
    <div class="parallelogramm blau">
      <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.typMitgliedschaft">
        <option :value="0">ordentliches Mitglied</option>
        <option :value="1">Interessent</option>
        <option :value="2">Fördermitglied</option>
      </select>
      <p>Status</p>
    </div>
    <div v-if="mitglied.typMitgliedschaft == 1 || mitglied.Eintrittsdatum === null" class="parallelogramm blau">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Antragsdatum" type="date" />
      <p>Antragsdatum</p>
    </div>
    <div v-if="(mitglied.typMitgliedschaft == 0 || mitglied.typMitgliedschaft == 2) && mitglied.Eintrittsdatum" class="parallelogramm blau">
      <input :disabled="Verband() > 0 || Zugriffsrechte() !== 0" v-model="mitglied.Mitgliedsnummer" type="number" />
      <p>Mitgliedsnummer</p>
    </div>
    <div v-if="Verband() === 0 || mitglied.typMitgliedschaft == 2" class="parallelogramm blau">
      <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Kreisverband">
        <option v-if="Verband() === 0 && mitglied.typMitgliedschaft == 2" :value="0">Landesverband</option>
        <option v-if="Verband() === 0 || (Verband() == 1 && mitglied.typMitgliedschaft == 2)" :value="1">Dresden</option>
        <option v-if="(Verband() === 0 || Verband() == 1) && mitglied.typMitgliedschaft == 2" :value="4">Dresden + LV</option>
        <option v-if="Verband() === 0 || (Verband() == 2 && mitglied.typMitgliedschaft == 2)" :value="2">Leipzig</option>
        <option v-if="(Verband() === 0 || Verband() == 2) && mitglied.typMitgliedschaft == 2" :value="5">Leipzig + LV</option>
        <option v-if="Verband() === 0 || (Verband() == 3 && mitglied.typMitgliedschaft == 2)" :value="3">Chemnitz</option>
        <option v-if="(Verband() === 0 || Verband() == 3) && mitglied.typMitgliedschaft == 2" :value="6">Chemnitz + LV</option>
        <option v-if="mitglied.typMitgliedschaft == 2" :value="7">LV + DD + L + CH</option>
      </select>
      <p>Kreisverband</p>
    </div>
    <div v-if="(mitglied.typMitgliedschaft == 0 || mitglied.typMitgliedschaft == 2) && Verband() === 0 && mitglied.Eintrittsdatum" class="parallelogramm blau">
      <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.ZAMaktuell">
        <option :value="1">ja</option>
        <option :value="0">nein</option>
      </select>
      <p>in eVEWA aktualisiert</p>
    </div>
    <div v-if="mitglied.typMitgliedschaft == 0 || mitglied.typMitgliedschaft == 2" class="parallelogramm blau">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Eintrittsdatum" type="date" />
      <p>Eintrittsdatum</p>
    </div>
    <div v-if="(mitglied.typMitgliedschaft == 0 || mitglied.typMitgliedschaft == 2) && mitglied.Eintrittsdatum" class="parallelogramm blau">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Austrittsdatum" type="date" />
      <p>Austrittsdatum</p>
    </div>
    <div v-if="(mitglied.typMitgliedschaft == 0 || mitglied.typMitgliedschaft == 2) && mitglied.Austrittsdatum" class="parallelogramm blau">
      <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.AustrittAbschluss">
        <option :value="0">Austritt in Bearbeitung</option>
        <option :value="1">Austritt abgeschlossen</option>
      </select>
      <p>Austritts-Status</p>
    </div>

    <h2>Persönliche Angaben</h2>
    <div class="parallelogramm wide gelb">
      <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Anrede">
        <option :value="null">keine Angabe</option>
        <option>Herr</option>
        <option>Frau</option>
        <option>Andere</option>
      </select>
      <p>Anrede</p>
    </div>
    <div class="parallelogramm gelb">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Nachname" type="text" />
      <p>Nachname</p>
    </div>
    <div class="parallelogramm gelb">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Vorname" type="text" />
      <p>Vorname</p>
    </div>
    <div class="parallelogramm gelb">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Geburtsdatum" type="date" />
      <p>Geburtsdatum</p>
    </div>

    <h2>Kontaktdaten</h2>
    <div class="parallelogramm magenta">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Straße" type="text" />
      <p>Straße + Hausnummer</p>
    </div>
    <div class="parallelogramm magenta">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Postleitzahl" type="text" />
      <p>Postleitzahl</p>
    </div>
    <div class="parallelogramm magenta">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Ort" type="text" />
      <p>Ort</p>
    </div>
    <div class="parallelogramm magenta">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Mail" type="email" />
      <p>E-Mail-Adresse</p>
    </div>
    <div class="parallelogramm magenta">
      <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Telefonnummer" type="tel" />
      <p>Telefonnummer</p>
    </div>

    <span v-if="mitglied.typMitgliedschaft == 0 || mitglied.typMitgliedschaft == 2">
      <h2>Beitragsinformationen</h2>
      <div v-if="mitglied.typMitgliedschaft == 0" class="parallelogramm blau">
        <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Beitragskategorie">
          <option :value="null">keine Angabe</option>
          <option v-for="i in 4" :value="i - 1">{{Beitragskategorien(i - 1)}}</option>
        </select>
        <p>Beitragskategorie</p>
      </div>
      <div class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Mitgliedsbeitrag" type="number" step="0.01" />
        <p>Mitgliedsbeitrag</p>
      </div>
      <div class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.bezahltAm" type="date" />
        <p>bezahlt am</p>
      </div>
      <div class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.bezahltBis" type="date" />
        <p>bezahlt bis</p>
      </div>
      <div class="parallelogramm blau">
        <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Zahlungsintervall">
          <option value="1">{{Intervalle(1)}}</option>
          <option value="3">{{Intervalle(3)}}</option>
          <option value="6">{{Intervalle(6)}}</option>
          <option value="12">{{Intervalle(12)}}</option>
          <option value="0">{{Intervalle(0)}}</option>
        </select>
        <p>Zahlungsintervall</p>
      </div>
      <div class="parallelogramm blau">
        <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Lastschrift">
          <option :value="1">ja</option>
          <option :value="0">nein</option>
        </select>
        <p>Lastschrift</p>
      </div>
      <div v-if="mitglied.Lastschrift" class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.DatumLastschriftmandat" type="date" />
        <p>Datum Lastschriftmandat</p>
      </div>
      <div v-if="mitglied.Lastschrift" class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Kontoinhaber" type="text" />
        <p>Kontoinhaber</p>
      </div>
      <div v-if="mitglied.Lastschrift" class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.IBAN" type="text" />
        <p>IBAN</p>
      </div>
      <div v-if="mitglied.Lastschrift" class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Kreditinstitut" type="text" />
        <p>Kreditinstitut</p>
      </div>
      <div v-if="mitglied.Lastschrift" class="parallelogramm blau">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.BIC" type="text" />
        <p>BIC</p>
      </div>
    </span>

    <span v-if="mitglied.typMitgliedschaft == 0">
      <h2>Wechsel</h2>
      <div class="parallelogramm gelb">
        <select :disabled="Zugriffsrechte() !== 0" v-model="mitglied.StatusWechsel">
          <option v-for="i in 5" :value="i - 1">{{WechselStatus(i - 1)}}</option>
        </select>
        <p>Status Wechsel</p>
      </div>
      <div v-if="mitglied.StatusWechsel" class="parallelogramm gelb">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Wechselwunsch" type="text" />
        <p>Wechselwunsch</p>
      </div>
      <div v-if="mitglied.StatusWechsel === 2 || mitglied.StatusWechsel === 4" class="parallelogramm gelb">
        <input :disabled="Zugriffsrechte() !== 0" v-model="mitglied.Wechseldatum" type="date" />
        <p>Wechseldatum</p>
      </div>
    </span>

    <h2>Bemerkungen</h2>
    <div class="parallelogramm magenta">
      <textarea :disabled="Zugriffsrechte() === 1" v-model="mitglied.Bemerkungen" rows="3" />
    </div>
    <div v-on:click="speichern" class="parallelogramm blau submit" :class="(mitglied.typMitgliedschaft !== 0 && Zugriffsrechte() === 0) ? 'zweiDrittel' : 'full'">
      Speichern
    </div>
    <div v-on:click="löschen" v-if="mitglied.typMitgliedschaft !== 0 && Zugriffsrechte() === 0" class="parallelogramm magenta submit einDrittel">
      Löschen
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Mitglieder',
  mixins: [globalFunctions],

  components: {

  },

  data () {
    return {
      mitglied: {},
      mitgliedAlt: {}
    }
  },

  methods: {
    getData: function () {
      if (this.$route.params.mitgliederId === 'neu') {
        this.mitglied = {
          mitgliederId: 'neu',
          Anrede: null,
          Vorname: '',
          Nachname: '',
          Geburtsdatum: null,
          Kreisverband: this.Verband() > 0 ? this.Verband() : null,
          Mitgliedsnummer: null,
          Straße: '',
          Postleitzahl: '',
          Ort: '',
          Telefonnummer: null,
          Mail: '',
          Beitragskategorie: null,
          Mitgliedsbeitrag: null,
          Zahlungsintervall: null,
          bezahltAm: null,
          bezahltBis: null,
          Lastschrift: null,
          DatumLastschriftmandat: null,
          Kontoinhaber: null,
          IBAN: null,
          BIC: null,
          Kreditinstitut: null,
          Eintrittsdatum: null,
          Austrittsdatum: null,
          AustrittAbschluss: 0,
          Bemerkungen: '',
          StatusWechsel: 0,
          Wechselwunsch: '',
          Wechseldatum: null,
          typMitgliedschaft: 0,
          Antragsdatum: null,
          ZAMaktuell: 0
        }
      } else {
        axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
          function: 'getPerson',
          username: localStorage.username,
          password: localStorage.password,
          mitgliederId: this.$route.params.mitgliederId
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          this.mitglied = response.data[0]

          this.mitgliedAlt = {}
          for (var Feld in response.data[0]) {
            this.mitgliedAlt[Feld] = response.data[0][Feld]
          }
        })
      }
    },

    parseNull: function (text) {
      if (text) {
        return text
      } else {
        return null
      }
    },

    fehlend: function () {
      var fehlend = ''
      if (!this.mitglied.Nachname) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Nachname'
      if (!this.mitglied.Vorname) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Vorname'
      switch (this.mitglied.typMitgliedschaft) {
        case 0:
          if (!this.mitglied.Kreisverband && this.Verband() === 0) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Kreisverband'
          if (!this.mitglied.Geburtsdatum) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Geburtsdatum'
          if (!this.mitglied.Straße) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Straße + Hausnummer'
          if (!this.mitglied.Postleitzahl) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Postleitzahl'
          if (!this.mitglied.Ort) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Ort'
          if (!this.mitglied.Mail) fehlend += (fehlend.length > 0 ? ', ' : '') + 'E-Mail-Adresse'
          break
        case 1:
          if (!this.mitglied.Kreisverband && this.Verband() === 0) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Kreisverband'
          if (!this.mitglied.Mail && !this.mitglied.Telefonnummer) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Telefonnummer oder E-Mail-Adresse'
          break
        case 2:
          if (!this.mitglied.Kreisverband && this.mitglied.Kreisverband !== 0) fehlend += (fehlend.length > 0 ? ', ' : '') + 'Kreisverband'
      }
      return fehlend
    },

    alter: function (geb) {
      var age = 0
      var jetzt = new Date()
      jetzt.setHours(24)
      jetzt.setMinutes(0)
      jetzt.setSeconds(0)
      while (geb.setFullYear(geb.getFullYear() + 1) < jetzt) {
        age++
      }
      return age
    },

    speichern: function () {
      if (this.fehlend()) {
        alert('Es fehlen noch folgende Angaben: ' + this.fehlend())
      } else {
        if (this.alter(new Date(this.mitglied.Geburtsdatum)) < 14) if (!confirm('Die Person ist zu jung für die JuliA. Trotzdem speichern?')) return null
        var data = {}
        var DatenZAM = ['Anrede', 'Vorname', 'Nachname', 'Geburtsdatum', 'Kreisverband', 'Mitgliedsnummer', 'Straße', 'Postleitzahl', 'Ort', 'Telefonnummer', 'Mail', 'Eintrittsdatum', 'AustrittAbschluss', 'StatusWechsel', 'typMitgliedschaft']
        switch (this.mitglied.typMitgliedschaft) {
          case 0: // ordentliches Mitglied
            for (var Feld1 in DatenZAM) {
              if (this.mitgliedAlt[DatenZAM[Feld1]] !== this.mitglied[DatenZAM[Feld1]] && this.mitgliedAlt.ZAMaktuell === this.mitglied.ZAMaktuell) {
                this.mitglied.ZAMaktuell = false
              }
            }
            if (this.mitglied.AustrittAbschluss === 1 && this.mitgliedAlt.Austrittsdatum !== this.mitglied.Austrittsdatum) {
              this.mitglied.ZAMaktuell = false
            }
            data = {
              function: 'changeMitglied',
              username: localStorage.username,
              password: localStorage.password,
              mitgliederId: this.mitglied.mitgliederId,
              Anrede: this.mitglied.Anrede,
              Vorname: this.mitglied.Vorname,
              Nachname: this.mitglied.Nachname,
              Geburtsdatum: this.mitglied.Geburtsdatum,
              Kreisverband: this.mitglied.Kreisverband,
              Mitgliedsnummer: this.parseNull(this.mitglied.Mitgliedsnummer),
              Straße: this.mitglied.Straße,
              Postleitzahl: this.mitglied.Postleitzahl,
              Ort: this.mitglied.Ort,
              Telefonnummer: this.parseNull(this.mitglied.Telefonnummer),
              Mail: this.mitglied.Mail,
              Beitragskategorie: this.mitglied.Beitragskategorie,
              Mitgliedsbeitrag: this.parseNull(this.mitglied.Mitgliedsbeitrag),
              Zahlungsintervall: this.parseNull(this.mitglied.Zahlungsintervall),
              bezahltAm: this.parseNull(this.mitglied.bezahltAm),
              bezahltBis: this.parseNull(this.mitglied.bezahltBis),
              Lastschrift: this.mitglied.Lastschrift,
              DatumLastschriftmandat: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.DatumLastschriftmandat) : null,
              Kontoinhaber: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.Kontoinhaber) : null,
              IBAN: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.IBAN) : null,
              BIC: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.BIC) : null,
              Kreditinstitut: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.Kreditinstitut) : null,
              Eintrittsdatum: this.parseNull(this.mitglied.Eintrittsdatum),
              Austrittsdatum: this.parseNull(this.mitglied.Austrittsdatum),
              AustrittAbschluss: this.parseNull(this.mitglied.Austrittsdatum) ? this.mitglied.AustrittAbschluss : 0,
              Bemerkungen: this.parseNull(this.mitglied.Bemerkungen),
              StatusWechsel: this.mitglied.StatusWechsel,
              Wechselwunsch: this.mitglied.StatusWechsel ? this.parseNull(this.mitglied.Wechselwunsch) : null,
              Wechseldatum: this.mitglied.StatusWechsel === 0 || this.mitglied.StatusWechsel === 2 || this.mitglied.StatusWechsel === 4 ? this.parseNull(this.mitglied.Wechseldatum) : null,
              typMitgliedschaft: 0,
              Antragsdatum: this.mitglied.Antragsdatum,
              ZAMaktuell: (this.mitglied.ZAMaktuell || !this.mitglied.Eintrittsdatum) ? 1 : 0
            }
            break
          case 1: // Interessent
            data = {
              function: 'changeMitglied',
              username: localStorage.username,
              password: localStorage.password,
              mitgliederId: this.mitglied.mitgliederId,
              Anrede: this.mitglied.Anrede,
              Vorname: this.mitglied.Vorname,
              Nachname: this.mitglied.Nachname,
              Geburtsdatum: this.parseNull(this.mitglied.Geburtsdatum),
              Kreisverband: this.mitglied.Kreisverband,
              Mitgliedsnummer: null,
              Straße: this.parseNull(this.mitglied.Straße),
              Postleitzahl: this.parseNull(this.mitglied.Postleitzahl),
              Ort: this.parseNull(this.mitglied.Ort),
              Telefonnummer: this.parseNull(this.mitglied.Telefonnummer),
              Mail: this.parseNull(this.mitglied.Mail),
              Beitragskategorie: null,
              Mitgliedsbeitrag: null,
              Zahlungsintervall: null,
              bezahltAm: null,
              bezahltBis: null,
              Lastschrift: null,
              DatumLastschriftmandat: null,
              Kontoinhaber: null,
              IBAN: null,
              BIC: null,
              Kreditinstitut: null,
              Eintrittsdatum: null,
              Austrittsdatum: null,
              AustrittAbschluss: 0,
              Bemerkungen: this.parseNull(this.mitglied.Bemerkungen),
              StatusWechsel: null,
              Wechselwunsch: null,
              Wechseldatum: null,
              typMitgliedschaft: 1,
              Antragsdatum: this.mitglied.Antragsdatum,
              ZAMaktuell: 1
            }
            break
          case 2: // Fördermitglied
            for (var Feld2 in DatenZAM) {
              if (this.mitgliedAlt[DatenZAM[Feld2]] !== this.mitglied[DatenZAM[Feld2]] && this.mitgliedAlt.ZAMaktuell === this.mitglied.ZAMaktuell) {
                this.mitglied.ZAMaktuell = false
              }
            }
            if (this.mitglied.AustrittAbschluss === 1 && this.mitgliedAlt.Austrittsdatum !== this.mitglied.Austrittsdatum) {
              this.mitglied.ZAMaktuell = false
            }
            data = {
              function: 'changeMitglied',
              username: localStorage.username,
              password: localStorage.password,
              mitgliederId: this.mitglied.mitgliederId,
              Anrede: this.mitglied.Anrede,
              Vorname: this.mitglied.Vorname,
              Nachname: this.mitglied.Nachname,
              Geburtsdatum: this.mitglied.Geburtsdatum,
              Kreisverband: this.mitglied.Kreisverband,
              Mitgliedsnummer: this.mitglied.Mitgliedsnummer,
              Straße: this.mitglied.Straße,
              Postleitzahl: this.mitglied.Postleitzahl,
              Ort: this.mitglied.Ort,
              Telefonnummer: this.parseNull(this.mitglied.Telefonnummer),
              Mail: this.mitglied.Mail,
              Beitragskategorie: null,
              Mitgliedsbeitrag: this.parseNull(this.mitglied.Mitgliedsbeitrag),
              Zahlungsintervall: this.parseNull(this.mitglied.Zahlungsintervall),
              bezahltAm: this.parseNull(this.mitglied.bezahltAm),
              bezahltBis: this.parseNull(this.mitglied.bezahltBis),
              Lastschrift: this.mitglied.Lastschrift,
              DatumLastschriftmandat: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.DatumLastschriftmandat) : null,
              Kontoinhaber: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.Kontoinhaber) : null,
              IBAN: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.IBAN) : null,
              BIC: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.BIC) : null,
              Kreditinstitut: this.mitglied.Lastschrift ? this.parseNull(this.mitglied.Kreditinstitut) : null,
              Eintrittsdatum: this.parseNull(this.mitglied.Eintrittsdatum),
              Austrittsdatum: this.parseNull(this.mitglied.Austrittsdatum),
              AustrittAbschluss: this.parseNull(this.mitglied.Austrittsdatum) ? this.mitglied.AustrittAbschluss : 0,
              Bemerkungen: this.parseNull(this.mitglied.Bemerkungen),
              StatusWechsel: null,
              Wechselwunsch: null,
              Wechseldatum: null,
              typMitgliedschaft: 2,
              Antragsdatum: this.mitglied.Antragsdatum,
              ZAMaktuell: this.mitglied.ZAMaktuell ? 1 : 0
            }
            break
        }
        console.log(data)
        axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          if (response.data === '') {
            this.$router.go(-1)
          } else {
            console.log(response.data)
          }
        })
      }
    },

    löschen: function () {
      if (confirm('Möchtest du diese Person wirklich löschen?')) {
        axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
          function: 'deleteMitglied',
          username: localStorage.username,
          password: localStorage.password,
          mitgliederId: this.mitglied.mitgliederId
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          if (response.data === '') {
            this.$router.go(-1)
          } else {
            console.log(response.data)
          }
        })
      }
    }
  },

  computed: {

  },

  mounted: function () {
    this.getData()
  },

  watch: {
    $route: function (to, from) {
      this.getData()
    }
  }
}

</script>

<style scoped>
h2 {
  margin: 2rem 0 0 2rem;
}

.parallelogramm {
  /* transform: skewX(-15deg); */
  width: calc(100% / 2 - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
  font-size: 1.25rem;
  padding: max(1.5rem, min(1.5%, 20px));
  margin: 1rem;
  float: left;
  border-radius: 1em;
}

.parallelogramm.gelb {
  background: var(--gelb);
  color: var(--magenta);
}

.parallelogramm.blau {
  background: var(--blau);
  color: var(--weiß);
}

.parallelogramm.magenta {
  background: var(--magenta);
  color: var(--weiß);
}

.wide {
  margin-right: calc(100% / 2);
}

.full {
  width: calc(100% - 2rem - 2 * max(1.5rem, min(2.5%, 20px)));
}

.einDrittel {
  width: calc((100% / 3 - 2rem - 2 * max(1.5rem, min(2.5%, 20px))));
}

.zweiDrittel {
  width: calc((100% / 3 - 2rem - 2 * max(1.5rem, min(2.5%, 20px))) * 2 + 2rem + 2 * max(1.5rem, min(2.5%, 20px)));
}

.parallelogramm input, .parallelogramm select, .parallelogramm textarea {
  border: 0;
  width: calc(100% - 2%);
  padding: 1%;
  margin: 0;
  color: var(--blau);
  background: var(--weiß);
}

.parallelogramm input[disabled], .parallelogramm select[disabled], .parallelogramm textarea[disabled] {
  background: var(--hellgrau);
}

.parallelogramm p {
  margin: 0.25rem 0 0.5rem 0;
  height: 0;
  font-family: 'Anybody ExtraBold';
}

main {
  overflow-x: hidden;
}

.submit {
  font-family: 'Anybody ExtraBold';
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 8rem;
  cursor: pointer;
}
</style>
