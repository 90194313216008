<template>
  <div v-if="rechnung || Verband() === 0" class="parallelogramm" :class="{bezahlt: bezahlt}">
    <span v-if="Verband() === 0 && Zugriffsrechte() === 0" class="verwaltung">
      <div v-if="rechnung" class="zahlStatus" v-on:click="togglePaid">
        <span v-if="bezahlt">✓</span>
        <span v-if="!bezahlt">€€€</span>
      </div>
      <div v-if="!rechnung" class="zahlStatus" v-on:click="toggleRechnung">
        <svg height="1.5em" version="1.1" viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(7 0 0 7 -55 -7080)">
          <path transform="translate(0 1002.4)" d="m15.508 11.186c-1.2433 0-2.2598 1.0145-2.2598 2.2578v23.115c0 1.2433 1.0164 2.2578 2.2598 2.2578h16.117c1.2433 0 2.2578-1.0145 2.2578-2.2578v-1.0234l-0.46094 0.19141-0.45117 0.1875v0.64453c0 0.7539-0.59181 1.3457-1.3457 1.3457h-16.117c-0.75389 0-1.3477-0.5918-1.3477-1.3457v-23.115c0-0.7539 0.59374-1.3457 1.3477-1.3457h16.117c0.75392 0 1.3457 0.5918 1.3457 1.3457v16.725l0.91211-0.96875v-15.756c0-1.2433-1.0145-2.2578-2.2578-2.2578h-16.117zm18.375 19.475-0.82031 0.87109 0.82031 0.77148v-1.6426zm-0.91211 2.7383v1.4355l0.06836-0.02734 0.54883-0.23047 0.29492-0.12109v-0.19727l-0.91211-0.85937z" color="#000000" color-rendering="auto" fill="#FFFFFF" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.922 1017.8a0.45599 0.45599 0 0 0 0.04687 0.9102h13.195a0.45599 0.45599 0 1 0 0 -0.9102h-13.195a0.45599 0.45599 0 0 0 -0.04687 0z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.969 1020.8a0.45605 0.45605 0 1 0 0 0.9121h13.195a0.45605 0.45605 0 0 0 0 -0.9121h-13.195z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.969 1023.9a0.45605 0.45605 0 1 0 0 0.9121h13.195a0.45605 0.45605 0 0 0 0 -0.9121h-13.195z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.969 1026.9a0.45605 0.45605 0 1 0 0 0.9121h13.195a0.45605 0.45605 0 0 0 0 -0.9121h-13.195z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.969 1029.9a0.45605 0.45605 0 1 0 0 0.9121h13.195a0.45605 0.45605 0 0 0 0 -0.9121h-13.195z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.969 1033a0.45605 0.45605 0 1 0 0 0.9121h13.195a0.45605 0.45605 0 0 0 0 -0.9121h-13.195z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <path d="m16.969 1036a0.45605 0.45605 0 1 0 0 0.9121h13.195a0.45605 0.45605 0 0 0 0 -0.9121h-13.195z" color="#000000" color-rendering="auto" fill="#FFFFFF" fill-rule="evenodd" image-rendering="auto" shape-rendering="auto" solid-color="#000000" style="block-progression:tb;isolation:auto;mix-blend-mode:normal;text-decoration-color:#000000;text-decoration-line:none;text-decoration-style:solid;text-indent:0;text-transform:none;white-space:normal"/>
          <g transform="matrix(.098732 .023273 -.023273 .098732 25.86 948.81)" fill="#555">
           <path transform="translate(0,552.36)" d="m303.57 167.92c-1.0038 0.0702-1.953 0.6237-2.4961 1.5644l-2.0039 3.4688 33.439 19.307 2.0039-3.4688c0.86903-1.5052 0.35676-3.4181-1.1484-4.2871l-28.006-16.168c-0.56445-0.32588-1.1868-0.45812-1.7891-0.41601zm-6.5859 8.6465-42.156 73.018 33.439 19.305 42.156-73.016-33.439-19.307zm-44.242 76.631-1.9863 3.4394c-0.86903 1.5052-0.35677 3.4161 1.1484 4.2852l28.006 16.168c1.5052 0.86903 3.4161 0.35676 4.2852-1.1484l1.9863-3.4394-33.439-19.305z"/>
           <path d="m249 850.66c-1.0612-0.61275-0.68138-3.6463-0.68138-3.6463l0.59844-5.8633 0.59864-5.8636 0.59845-5.863 0.59855-5.8633 0.59844-5.8633 0.59864-5.8633 0.59845-5.8633 4.18 2.4133 4.18 2.4133 4.18 2.4133 4.18 2.4133 4.18 2.4133 4.18 2.4133 4.18 2.4133 4.18 2.4133-4.7784 3.45-4.7786 3.45-4.7784 3.45-4.7785 3.45-4.7784 3.45-4.7786 3.45-4.7784 3.45s-2.4374 1.8458-3.4986 1.233z"/>
          </g>
          <path transform="translate(0 1002.4)" d="m39.068 24.623c-0.10074-0.016-0.20574 0.01766-0.28125 0.09766l-0.2793 0.29492 2.8516 2.6836 0.2793-0.29492c0.12083-0.1284 0.11471-0.32842-0.01367-0.44922l-2.3887-2.248c-0.04815-0.045-0.10752-0.07398-0.16797-0.08398zm-0.85156 0.70117-5.8613 6.2285 2.8535 2.6836 5.8613-6.2285-2.8535-2.6836zm-6.1504 6.5352v2e-3l-0.0332 0.03516-0.24219 0.25781c-0.1162 0.12348-0.11248 0.31176 2e-3 0.43359l-0.15039 0.4375-0.19531 0.56445-0.19531 0.56641-0.19531 0.56445-0.19727 0.56445-0.19531 0.56641s-0.10809 0.29-0.01758 0.375c0.09051 0.0855 0.375-0.04102 0.375-0.04102l0.55274-0.22852 0.55078-0.23047 0.55273-0.22852 0.55274-0.22852 0.55078-0.23047 0.42773-0.17773c0.12858 0.10748 0.31716 0.10057 0.43359-0.02344l0.24023-0.25586 0.0039-2e-3 0.03125-0.03516-2.8516-2.6855z" fill="#FFFFFF"/>
          </g>
        </svg>
      </div>
      <div v-if="!rechnung" class="reloadUmlage" v-on:click="refreshStatistics">
        ↻
      </div>
      <div v-if="rechnung && !bezahlt" class="reloadUmlage" v-on:click="toggleRechnung">
        ↩
      </div>
    </span>
    <h2>
      Quartal 0{{Math.floor(new Date(Datum).getMonth() / 3) + 1}}/{{new Date(Datum).getYear() % 100}}
      <span v-if="Verband() === 0"> - {{Kreisverbände[KV]}}</span>
    </h2>
    <p>Stichtag: {{new Date(Datum).getDate() + '.' + (new Date(Datum).getMonth() + 1) + '.' + new Date(Datum).getFullYear()}}</p>
    <table>
      <tr v-for="Kategorie in 4">
        <td class="Beschreibung">{{Beitragskategorien[Kategorie - 1]}}</td>
        <td class="Anzahl">{{parseInt(Anzahlen[Kategorie - 1])}} x</td>
        <td class="Betrag monospace">{{parseFloat(Betrage[Kategorie - 1]).toFixed(2)}} €</td>
      </tr>
      <tr>
        <td class="Monate sum">x 3 Monate</td>
        <td class="sum"></td>
        <td class="Betrag sum ende">{{parseFloat(3 * Summe).toFixed(2)}} €</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Umlagefeld',
  mixins: [globalFunctions],
  props: {
    Datum: { type: String, default: '1970-01-01' },
    KV: { type: Number, default: 0 },
    rechnungProp: { type: Boolean, default: false },
    bezahltProp: { type: Boolean, default: false },
    Betrag0: { type: Number, default: 0 },
    Betrag1: { type: Number, default: 0 },
    Betrag2: { type: Number, default: 0 },
    Betrag3: { type: Number, default: 0 },
    Anzahl0: { type: Number, default: 0 },
    Anzahl1: { type: Number, default: 0 },
    Anzahl2: { type: Number, default: 0 },
    Anzahl3: { type: Number, default: 0 }
  },

  data () {
    return {
      Beitragskategorien: ['Schüler', 'Azubis/Studenten', 'Wehrdienst/FSJ/Arbeitslose', 'Berufstätige'],
      Kreisverbände: ['LV', 'DD', 'L', 'CH'],
      bezahlt: null,
      rechnung: null
    }
  },

  methods: {
    refreshStatistics: function () {
      if (!confirm('Sollen die Mitgliedszahlen zum Stichtag aktualisiert werden?')) return null
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/refreshStatistics.php', {
        username: localStorage.username,
        password: localStorage.password,
        Datum: this.Datum,
        Kreisverband: this.KV
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data !== '') {
          console.log(response.data)
        } else {
          location.reload()
        }
      })
    },

    toggleRechnung: function () {
      if (!confirm(this.rechnung ? 'Möchtest du die Rechnung zurücknehmen?' : 'Wurde die Rechnung wirklich gestellt?')) return null
      this.rechnung = !this.rechnung
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
        function: 'setUmlageStatus',
        username: localStorage.username,
        password: localStorage.password,
        Datum: this.Datum,
        Kreisverband: this.KV,
        bezahlt: this.bezahlt,
        rechnung: this.rechnung
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data !== '') {
          console.log(response.data)
        }
      })
    },

    togglePaid: function () {
      if (!confirm(this.bezahlt ? 'Möchtest du die Umlage-Bezahlung zurücknehmen?' : 'Wurde die Umlage wirklich bezahlt?')) return null
      this.bezahlt = !this.bezahlt
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
        function: 'setUmlageStatus',
        username: localStorage.username,
        password: localStorage.password,
        Datum: this.Datum,
        Kreisverband: this.KV,
        bezahlt: this.bezahlt,
        rechnung: this.rechnung
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data !== '') {
          console.log(response.data)
        }
      })
    }
  },

  computed: {
    Betrage: function () {
      return [this.Betrag0, this.Betrag1, this.Betrag2, this.Betrag3]
    },

    Anzahlen: function () {
      return [this.Anzahl0, this.Anzahl1, this.Anzahl2, this.Anzahl3]
    },

    Summe: function () {
      var sum = 0
      for (var i = 0; i < 4; i++) {
        sum += this.Betrage[i] * this.Anzahlen[i]
      }
      return sum
    }
  },

  mounted: function () {
    this.bezahlt = this.bezahltProp
    this.rechnung = this.rechnungProp
  }
}
</script>

<style scoped>
h2 {
  float: left;
  margin: 0;
  font-size: 1.5em;
  width: 100%;
  color: var(--gelb);
}

.verwaltung {
  font-size: 1.5em;
  transform: skewX(15deg);
  font-weight: bold;
}

.zahlStatus {
  z-index: 2;
  position: absolute;
  right: 0em;
  top: 0em;
  padding: 2rem;
  cursor: pointer;
}

.reloadUmlage {
  z-index: 2;
  position: absolute;
  left: 0em;
  top: 0em;
  padding: 2rem;
  cursor: pointer;
}

.parallelogramm {
  /* transform: skewX(-15deg); */
  width: calc(100% / 2 - 4rem - 2 * max(1.5rem, min(2.5%, 20px)));
  font-size: 1.5rem;
  padding: max(1.5rem, min(2.5%, 20px));
  margin: 1rem 2rem 1rem 2rem;
  float: left;
  text-align: center;
  color: var(--weiß);
  background: var(--magenta);
  border-radius: 1em;
  position: relative;
}

.parallelogramm.bezahlt {
  background: var(--magentaHell);
  /* opacity: 0.6; */
}

@media screen and (orientation:portrait) {
  .parallelogramm {
    width: calc(100% - 4rem - 2 * max(1.5rem, min(2.5%, 20px)));
  }
}
@media screen and (orientation:landscape) {
  .parallelogramm {
    width: calc(100% / 2 - 4rem - 2 * max(1.5rem, min(2.5%, 20px)));
  }
}

.parallelogramm.button {
  cursor: pointer;
}

.parallelogramm p {
  /* transform: skewX(15deg); */
  margin: 0;
}

table {
  width: 100%;
  /* transform: skewX(15deg); */
  border-collapse: collapse;
}

tr {
  background: none;
}

td.Beschreibung {
  text-align: left;
}

td.Anzahl {
  text-align: right;
}

td.Betrag {
  text-align: right;
}

td.Monate {
  text-align: right;
}

td.sum {
  border-top: 1px solid var(--weiß);
}

td.sum.ende {
  padding-left: 0;
  font-weight: bold;
  border-bottom: 5px double var(--weiß);
}

td.monospace {
  font-variant-numeric: tabular-nums;
}
</style>
