<template>
  <router-view />
</template>

<style>

@font-face {
  font-family: 'Anybody';
  src: url('./assets/fonts/Anybody-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Anybody ExtraBold';
  src: url('./assets/fonts/Anybody-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Anybody Light';
  src: url('./assets/fonts/Anybody-Light.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Anybody Light';
  src: url('./assets/fonts/Anybody-Bold.ttf') format('truetype');
  font-weight: bold;
}

:root {
  --magenta: #E5007D;
  --magentaHell: #f5b5d2;
  --gelb: #FFED00;
  --blau: #009EE3;
  --blauHell: #a1daf8;
  --gelbHell: #fff7b2;
  --schwarz: #000000;
  --weiß: #FFFFFF;
  --hellgrau: #DDDDDD;

  --heightMenu: 3rem;

  font-size: min(2rem, max(0.7vw, 1vh));
}

body {
  margin: 0;
  overflow: hidden;
  user-select: none;
  background: var(--gelbHell);
}

#app {
  font-family: 'Anybody Light', Calibri, sans-serif;
  color: var(--schwarz);
  overflow: hidden;
}

h1 {
  font-family: 'Anybody ExtraBold';
  text-transform: uppercase;
  color: var(--magenta);
  margin-top: 0;
}

h2 {
  font-family: 'Anybody ExtraBold';
  text-transform: uppercase;
  color: var(--blau);
  float: left;
  width: 100%;
}

a {
  text-decoration: none;
}

input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Anybody Light', Calibri, sans-serif;
  border-radius: 0;
  min-height: 1em;
}

input[type=checkbox] {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  margin: 0;
  user-select: none;
}

input:focus, select:focus, textarea:focus {
  outline: none;
}

table, th, td {
  border-spacing: 0;
}

table {
  user-select: text;
  overflow: hidden;
  margin-right: 1rem;
  border-radius: 1em;
}

tr:nth-child(2n) {
  background: var(--weiß);
}

tr:nth-child(2n+1) {
  background: var(--gelbHell);
}

tr:hover {
  background: var(--gelb);
}

th, td {
  padding: 2.5px 5px;
  text-align: center;
  white-space: nowrap;
}

th {
  background: var(--blau);
  color: var(--weiß);
  font-size: 1.1em;
  font-family: 'Anybody ExtraBold'
}

td {
  font-family: 'Anybody Light'
}
</style>
